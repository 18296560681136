<template>
  <v-card color="transparent" tile elevation="0">
    <v-row justify="center" class="my-8" no-gutters align="center" style="min-height: 350px">
      <v-col cols="11" lg="9">
        <div class="text-center">
          <p class="text-body-1">{{ $t(`label.processingYourOrder`) }}</p>
          <p class="text-body-1">{{ $t(`label.pleaseDontRefreshPage`) }}</p>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { ORDER_UPDATE_STATUS, ORDER_CREATE_PAYMENT_ORDER } from '@/store/order.module'
import { PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID } from '@/store/payment.module'
import { SESSION } from '@/constants'
export default {
  data: () => ({
    paymentInfo: {}
  }),
  computed: {
    paymentTransactionInfo() {
      return this.$store.state.payment.paymentTransactionInfo
    },
    createOrderPaymentResponseComplete() {
      return this.$store.state.order.createOrderPaymentResponse.complete
    }
  },
  watch: {
    async createOrderPaymentResponseComplete() {
      let response = this.$store.state.order.createOrderPaymentResponse

      if (response.complete) {
        if (!response.success) {
          /* Show Generic Error */
          /* Redirect To History Page*/
          response.code = '9999'
          this.$root.$master.responseCompleteDialog(response)
          this.$router.push({
            name: ROUTE_NAME.HOME
          })
        } else {
          let data = {
            isSendEmail: true,
            isOrderSealed: true,
            orderUuid: this.paymentInfo.orderUuid,
            action: 'sealOrder'
          }
          await this.$store.dispatch(ORDER_UPDATE_STATUS, { data })
          this.$router.push({
            name: ROUTE_NAME.PAYMENT_COMPLETE_SUCCESS
          })
        }
      }
    },
    async paymentTransactionInfo() {
      if (this.paymentTransactionInfo.status.toLowerCase() == 'success') {
        let data = {
          paymentMethod: this.paymentInfo.paymentMethod,
          paymentType: this.paymentInfo.paymentType,
          amount: this.paymentInfo.amount,
          paymentUuid: this.paymentTransactionInfo.uuid,
          orderUuid: this.paymentInfo.orderUuid,
          referenceNumber: this.paymentInfo.reference_id,
          paymentGatewayRoutingUuid: this.paymentInfo.gateway_routing_uuid
        }
        await this.$store.dispatch(ORDER_CREATE_PAYMENT_ORDER, { data })
      } else if (this.paymentTransactionInfo.status.toLowerCase() == 'processing') {
        let data = {
          orderUuid: this.paymentInfo.orderUuid,
          id: this.paymentInfo.reference_id,
          merchant_trx_id: this.paymentInfo.payment_trx_id
        }
        await this.$store.dispatch(PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID, { data })
      } else if (this.paymentTransactionInfo.status.toLowerCase() == 'fail') {
        this.$router.push({
          name: ROUTE_NAME.PAYMENT_COMPLETE_FAIL
        })
      }
    }
  },
  async created() {
    await this.checkPaymentTransactionInfo()
  },
  methods: {
    async checkPaymentTransactionInfo() {
      this.paymentInfo = JSON.parse(localStorage.getItem(SESSION.PAYMENT_TRANSACTION_INFO))
      localStorage.removeItem(SESSION.PAYMENT_TRANSACTION_INFO)
      if (this.paymentInfo == null) {
        this.$router.push({
          name: ROUTE_NAME.HOME
        })
      } else {
        let data = {
          orderUuid: this.paymentInfo.orderUuid,
          id: this.paymentInfo.reference_id,
          merchant_trx_id: this.paymentInfo.payment_trx_id
        }
        /* Set Interval Until Success */
        await this.$store.dispatch(PAYMENT_TRANSACTION_BY_MERCHANT_TRANSACTION_ID, { data })
      }
    }
  }
}
</script>
